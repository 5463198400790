<template>
  <div>
    <div class="container">
      <div
        class="row container-small d-flex justify-content-center align-items-center flex-column"
      >
        <div
          class="container-small-1 d-flex justify-content-center align-items-center flex-column"
        >
          <img
            class="big-img bounce-top"
            src="@/static/image/smart-1.png"
            alt=""
          />
          <div
            class="d-flex justify-content-center align-items-center flex-column"
            style="margin-top: -40px"
          >
            <h3 class="title tracking-in-expand">投融资智能匹配系统</h3>
            <div class="text">
              这是一款高效帮助企业快速获得融资、帮助机构寻找优质客户的系统。可有效减少企业融资盲选、机构面对无资质企业申请时的低效率现状。它以中壬银兴构筑的信贷服务生态为基础，首先通过中壬云债权评测系统评估企业信贷价值，其次系统进一步筛选出优质企业，并将这些企业的资质与生态内金融机构的申请条件相关联，为资质条件相符的双方实现智能匹配。
            </div>
          </div>
        </div>

        <div
          class="container-small-2 d-flex justify-content-center align-items-center flex-column"
          @mouseenter="enter()"
          @mouseleave="leave()"
        >
          <img
            :src="amendImage"
            alt=""
            class="small-img"
          />
          <h5 class="title-small">投融资智能匹配</h5>
        </div>

        <div class="text-small">
          帮助信贷生态链上的金融机构与小微企业完成资质筛选匹配，提升双方效率。
        </div>
      </div>
    </div>
    <div class="footer">
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import videojs from "video.js";
import Footer from "@/components/footer.vue";
export default {
  components: {
    Footer,
  },
  methods: {
    enter(){
      this.amendImage = require("@/static/image/smart-2-sel.png");
    },
    leave(){
       this.amendImage =require("@/static/image/smart-2.png");
    }

  },
  data() {
    return {
      amendImage:  require("@/static/image/smart-2.png"),
    };
  },
};
</script>

<style lang="less" scoped>
.container {
  margin-bottom: 50px;
  .container-small {
    .text,
    .text-small {
      text-align: center;
      width: 88%;
      color: #6f76b0;
      line-height: 2;
      font-size: 18px;
      text-indent: 2em;
    }
    .text {
      text-align: left;
    }
    .container-small-1 {
      margin-top: 50px;
      .title {
        color: white;
        padding-bottom: 5px;
      }
    }

    .container-small-2 {
      margin-top: 50px;
      text-align: center !important;
      .title-small {
        // padding-bottom: 5px;
        color: #808cecff;
        padding-top: 8px;
        text-indent: 0em;
      }
    }
  }
}
// 文字出现
.tracking-in-expand {
  animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.61, 0.355, 1) both;
}

@keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
// 最上边
.bounce-top {
  animation: bounce-top 0.9s;
}
@keyframes bounce-top {
  0% {
    transform: translateY(-45px);
    animation-timing-function: ease-in;
    opacity: 1;
  }
  24% {
    opacity: 1;
  }
  40% {
    transform: translateY(-24px);
    animation-timing-function: ease-in;
  }
  65% {
    transform: translateY(-12px);
    animation-timing-function: ease-in;
  }
  82% {
    transform: translateY(-6px);
    animation-timing-function: ease-in;
  }
  93% {
    transform: translateY(-4px);
    animation-timing-function: ease-in;
  }
  25%,
  55%,
  75%,
  87% {
    transform: translateY(0px);
    animation-timing-function: ease-out;
  }
  100% {
    transform: translateY(0px);
    animation-timing-function: ease-out;
    opacity: 1;
  }
}
</style>